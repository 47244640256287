import { render, staticRenderFns } from "./SvodView.vue?vue&type=template&id=689fa6bc&scoped=true&"
import script from "./SvodView.vue?vue&type=script&lang=ts&"
export * from "./SvodView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "689fa6bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/filmination-frontend/filmination-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('689fa6bc')) {
      api.createRecord('689fa6bc', component.options)
    } else {
      api.reload('689fa6bc', component.options)
    }
    module.hot.accept("./SvodView.vue?vue&type=template&id=689fa6bc&scoped=true&", function () {
      api.rerender('689fa6bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Administrators/SvodView.vue"
export default component.exports