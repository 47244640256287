var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen" }, [
    _c(
      "div",
      {
        staticClass: "h-16 container mx-auto grid grid-cols-12 tracking-wider",
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "col-span-5 text-4xl text-left mt-3 ml-2 font-semibold",
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("pages.users.profile.company_name")) + " "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-span-7 flex items-end justify-between" },
          [
            _vm._m(0),
            _vm.cpmpanyEdit
              ? _c("div", { staticClass: "flex mt-4 font-bold" }, [
                  _c(
                    "div",
                    {
                      staticClass: "py-2 px-2 mx-1 action_btn",
                      on: {
                        click: function ($event) {
                          _vm.cpmpanyEdit = !_vm.cpmpanyEdit
                        },
                      },
                    },
                    [_vm._v("cancel")]
                  ),
                  _c("div", { staticClass: "py-2 px-4 ml-2 mr-4 action_btn" }, [
                    _vm._v("Save"),
                  ]),
                ])
              : _c("div", { staticClass: "flex mt-4 font-bold" }, [
                  _c(
                    "div",
                    {
                      staticClass: "py-2 px-2 mx-1 action_btn",
                      on: {
                        click: function ($event) {
                          _vm.cpmpanyEdit = !_vm.cpmpanyEdit
                        },
                      },
                    },
                    [_vm._v("Edit")]
                  ),
                ]),
          ]
        ),
      ]
    ),
    _c("div", { staticClass: "container mx-auto mt-22" }, [
      _c("div", { staticClass: "grid grid-cols-12 mb-6 text-lg" }, [
        _c("div", { staticClass: "col-span-5 text-right mr-8 font-semibold" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.name"))),
        ]),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.mail_address")) + " ")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mailAddress,
                    expression: "mailAddress",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.mailAddress },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.mailAddress = $event.target.value
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.phone_number")) + " ")]
        ),
        _vm.cpmpanyEdit
          ? _c(
              "div",
              { staticClass: "col-span-5 text-left ml-3" },
              [
                _c("VuePhoneNumberInput", {
                  on: { update: _vm.getPhoneNumber },
                  model: {
                    value: _vm.phoneNumber,
                    callback: function ($$v) {
                      _vm.phoneNumber = $$v
                    },
                    expression: "phoneNumber",
                  },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.timezone")) + " ")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.timezone,
                    expression: "timezone",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.timezone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.timezone = $event.target.value
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.genre")) + " ")]
        ),
        _vm.cpmpanyEdit
          ? _c(
              "div",
              { staticClass: "col-span-5 text-left ml-3 genre-field" },
              [
                _c("InputGenreField", {
                  attrs: { autocompleteItems: _vm.autocompleteItems },
                  on: { inputGenres: _vm.setInputGenres },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("commons.fields.distribution_area")) + " "
            ),
          ]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.distributionArea,
                    expression: "distributionArea",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.distributionArea },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.distributionArea = $event.target.value
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _vm.cpmpanyEdit
        ? _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-span-5 text-right mr-8 tracking-wide font-semibold",
              },
              [_vm._v("Company website")]
            ),
            _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyWebsite,
                    expression: "companyWebsite",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.companyWebsite },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyWebsite = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "grid grid-cols-12 mt-4 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("commons.fields.works_under_negotiation")) +
                " "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-span-5 text-left ml-3" },
          [_c("ChangeFieldButton", { attrs: { opened: false } })],
          1
        ),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.movie_type")) + " ")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.movieType,
                      expression: "movieType",
                    },
                  ],
                  staticClass: "w-full h-10 bg-white border border-brown-grey",
                  attrs: { name: "movie_type" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.movieType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.movieTypeOption, function (option, key) {
                  return _c(
                    "option",
                    { key: key, domProps: { value: option } },
                    [_vm._v(_vm._s(option))]
                  )
                }),
                0
              ),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.freeword")) + " ")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.freeword,
                    expression: "freeword",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.freeword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.freeword = $event.target.value
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v("Country")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryAddress.country,
                    expression: "deliveryAddress.country",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.deliveryAddress.country },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.deliveryAddress,
                      "country",
                      $event.target.value
                    )
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v("Full Name")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryAddress.fullName,
                    expression: "deliveryAddress.fullName",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.deliveryAddress.fullName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.deliveryAddress,
                      "fullName",
                      $event.target.value
                    )
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v("Street")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryAddress.street,
                    expression: "deliveryAddress.street",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.deliveryAddress.street },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.deliveryAddress, "street", $event.target.value)
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v("City")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryAddress.city,
                    expression: "deliveryAddress.city",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.deliveryAddress.city },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.deliveryAddress, "city", $event.target.value)
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v("State/Province/Region")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryAddress.stateProvinceRegion,
                    expression: "deliveryAddress.stateProvinceRegion",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.deliveryAddress.stateProvinceRegion },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.deliveryAddress,
                      "stateProvinceRegion",
                      $event.target.value
                    )
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
        _c(
          "div",
          {
            staticClass:
              "col-span-5 text-right mr-8 tracking-wide font-semibold",
          },
          [_vm._v("Zip/Postal Code")]
        ),
        _vm.cpmpanyEdit
          ? _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryAddress.zipPostalCode,
                    expression: "deliveryAddress.zipPostalCode",
                  },
                ],
                staticClass: "input_text",
                attrs: { type: "text" },
                domProps: { value: _vm.deliveryAddress.zipPostalCode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.deliveryAddress,
                      "zipPostalCode",
                      $event.target.value
                    )
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "col-span-5 text-left ml-3" }, [
              _vm._v("tttttttt"),
            ]),
      ]),
    ]),
    _vm.userRole == "admin"
      ? _c(
          "div",
          { staticClass: "admin_contents mt-12 border-t-2 bg-light-grey pb-2" },
          [
            _c("div", { staticClass: "container mx-auto mt-6" }, [
              _c("div", { staticClass: "grid grid-cols-12 text-lg pt-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-5 text-right mr-8 tracking-wide font-semibold",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("commons.fields.previous_purchase_amount")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-7 text-left ml-3 tracking-wide font-semibold",
                  },
                  [_vm._v("￥00,000,000")]
                ),
              ]),
              _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-5 text-right mr-8 tracking-wide font-semibold",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("commons.fields.purchase_genre_ratio")) +
                        " "
                    ),
                  ]
                ),
                _vm._m(2),
              ]),
              _c("div", { staticClass: "grid grid-cols-12 mt-2 text-lg" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-5 text-right mr-8 tracking-wide font-semibold",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("commons.fields.purchase_format_ratio")) +
                        " "
                    ),
                  ]
                ),
                _vm._m(3),
              ]),
              _c("div", { staticClass: "grid grid-cols-12 mb-6 text-lg" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-5 text-right mr-8 tracking-wide font-semibold",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("commons.fields.annual_purchase")) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "col-span-7 text-left ml-3" }, [
                  _vm._v("00"),
                ]),
              ]),
              _c("div", { staticClass: "grid grid-cols-12 mb-6 text-lg" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-span-5 text-right mr-8 tracking-wide font-semibold",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("commons.fields.annual_purchase")) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "col-span-7 text-left ml-3" }, [
                  _vm._v("000"),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("div", { staticClass: "py-2 px-5 h-10 ml-3 web_btn" }, [
        _c("span", { staticClass: "inline-block" }, [_vm._v("web")]),
        _c("img", {
          staticClass: "inline-block w-4 h-4 ml-2",
          attrs: { src: require("@/assets/image/icon_web_link.svg") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "grid grid-cols-12 mt-6 text-lg" }, [
      _c(
        "div",
        {
          staticClass: "col-span-5 text-right mr-8 tracking-wide font-semibold",
        },
        [_vm._v("Delivery Address")]
      ),
      _c("div", { staticClass: "col-span-5 text-left ml-3" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-span-7 text-left ml-3 grid grid-cols-7 text-center text-sm",
      },
      [
        _c(
          "div",
          { staticClass: "col-span-1 px-2 py-1 mb-4 font-semibold bg-red-400" },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-span-1 px-2 py-1 mb-4 font-semibold bg-yellow-500",
          },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-span-1 px-2 py-1 mb-4 font-semibold bg-yellow-200",
          },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass: "col-span-1 px-2 py-1 mb-4 font-semibold bg-green-400",
          },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass: "col-span-1 px-2 py-1 mb-4 font-semibold bg-blue-300",
          },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass: "col-span-1 px-2 py-1 mb-4 font-semibold bg-blue-600",
          },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-span-1 px-2 py-1 mb-4 font-semibold bg-purple-600",
          },
          [_vm._v("Genre")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-span-7 text-left ml-3 grid grid-cols-3 text-center text-sm",
      },
      [
        _c(
          "div",
          { staticClass: "col-span-1 px-2 py-1 mb-5 font-semibold bg-red-400" },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-span-1 px-2 py-1 mb-5 font-semibold bg-yellow-500",
          },
          [_vm._v("Genre")]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-span-1 px-2 py-1 mb-5 font-semibold bg-yellow-200",
          },
          [_vm._v("Genre")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }