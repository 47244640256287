var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      !_vm.opened
        ? [
            _c(
              "button",
              {
                staticClass:
                  "h-full w-full py-1 bg-very-light-grey text-xl font-semibold text-black rounded",
                class: [_vm.disabled ? "bg-very-light-grey" : "bg-brown-grey"],
                attrs: { disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    return _vm.clickButton("open")
                  },
                },
              },
              [
                _vm._v(
                  " + " +
                    _vm._s(_vm.$t("components.changeFieldButton.more")) +
                    " "
                ),
              ]
            ),
          ]
        : [
            _c(
              "button",
              {
                staticClass:
                  "h-full w-full py-1 bg-very-light-grey text-xl font-semibold text-black",
                class: [_vm.disabled ? "bg-very-light-grey" : "bg-brown-grey"],
                attrs: { disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    return _vm.clickButton("close")
                  },
                },
              },
              [
                _vm._v(
                  " - " +
                    _vm._s(_vm.$t("components.changeFieldButton.less")) +
                    " "
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }